<template>
  <div class="valuation-request-list">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Danh sách yêu cầu định giá'">
      <template v-slot:toolbar>
        <ValuationRequestToolBar
          :selectedValuationRequest="selectedValuationRequest"
          @show-dialog="handleShowDialogSelectedRequiredStocks"
        />
      </template>
      <template v-slot:preview>
        <ValuationRequestFilter
          :apiParams.sync="apiParams"
          @filter="handleFilter"
        />

        <ValuationRequestTable
          :customerSupportForms="customerSupportForms"
          :selectedValuationRequest.sync="selectedValuationRequest"
          :loading="onLoading"
          @select="handleSelectedValuationRequest"
          @selectAll="handleSelectAllValuationRequest"
          @update-note="onSelectUpdateNote"
        />
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="onChangePage"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>

    <ValuationRequestUpdateStatusModal @refresh="onRefreshForms" />

    <ValuationRequestUpdateNoteModal
      :showEditNoteModal="showEditNoteModal"
      :orderNoteModel.sync="orderNoteModel"
      @update="onUpdateNote"
    />

  </div>
</template>

<script>
import ValuationRequestTable from '@/view/components/valuation-request/ValuationRequestTable.vue';
import ValuationRequestFilter from '@/view/components/valuation-request/ValuationRequestFilter.vue';
import ValuationRequestToolBar from '@/view/components/valuation-request/ValuationRequestToolBar.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';

import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { makeToastFaile, formatDate, removeAccents } from '@/utils/common';
import { TIME_OUT } from '@/utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import { EventBus } from '@/core/services/event-bus';
import { UpdateNoteModel } from '@/view/pages/valuation-request/models';
import { SET_VALUATION_REQUEST_SELECTED } from '@/core/services/store/modules/trade-in/valuation-request.module.js';

const ValuationRequestUpdateStatusModal = () =>
  import(
    /* webpackPrefetch: true */ '@/view/components/valuation-request/ValuationRequestUpdateStatusModal.vue'
  );
const ValuationRequestUpdateNoteModal = () =>
  import(
    /* webpackPrefetch: true */ '@/view/components/valuation-request/ValuationRequestUpdateNoteModal.vue'
  );

export default {
  components: {
    KTCodePreview,
    Loader,
    ValuationRequestTable,
    ValuationRequestFilter,
    ValuationRequestToolBar,
    ValuationRequestUpdateStatusModal,
    ValuationRequestUpdateNoteModal,
  },
  data() {
    return {
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      apiParams: {
        customerPhoneSearch: null,
        customerId: null,
        description: null,
        note: null,
        fromDate: null,
        toDate: null,
        status: null,
      },
      customerSupportForms: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      selectedValuationRequest: [],
      showEditNoteModal: false,
      orderNoteModel: {},
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    selected() {
      return this.customerSupportForms.filter((module) => module.selected);
    },
  },
  methods: {
    formatDate,
    onRefreshForms() {
      this.selectedValuationRequest = [];
      this.getListCustomerSupportForm();
    },
    onChangePage() {
      this.getListCustomerSupportForm();
    },
    handleFilter() {
      this.onRefreshPageOne();
      this.getListCustomerSupportForm();
    },
    onRefreshPageOne() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-valuation-request',
      });
    },
    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const apiParams = cloneDeep(this.apiParams);

      const paramsFilter = {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        ...apiParams,
        fromDate: this.apiParams.fromDate
          ? moment(apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
        toDate: this.apiParams.toDate
          ? moment(apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
      };

      return paramsFilter;
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },

    async getListCustomerSupportForm() {
      if (!this.onLoading) {
        this.onLoading = true;
        this.customerSupportForms = [];
        const params = this.getParamFilters();

        ApiService.query(
          'valuation-requests',
          { params },
          { timeout: TIME_OUT },
        )
          .then((response) => {
            const dataset = response.data.data;
            this.totalItem = dataset.total;
            this.numberOfPage = dataset.total_page;
            this.customerSupportForms = dataset.data;
            this.onLoading = false;
          })
          .catch((error) => {
            this.onLoading = false;
            this.$nprogress.done();
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi',
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          });
      }
    },
    downloadExcel() {
      this.$bvModal.show('modal-warranty-request-excel');
    },
    handleShowDialogSelectedRequiredStocks(items) {
      EventBus.$emit('popup-update-status', items);
    },
    handleSelectedValuationRequest() {
      const clonedRequests = [...this.customerSupportForms];
      const selected = clonedRequests.filter((item) => item.selected);
      this.$store.dispatch(SET_VALUATION_REQUEST_SELECTED, selected);
      this.selectedValuationRequest = selected;
    },
    handleSelectAllValuationRequest(checked) {
      const clonedRequests = this.customerSupportForms.map((item) => {
        return { ...item, selected: checked };
      });
      this.customerSupportForms = clonedRequests;
      this.$store.dispatch(
        SET_VALUATION_REQUEST_SELECTED,
        checked ? clonedRequests : [],
      );
      this.selectedValuationRequest = checked ? clonedRequests : [];
    },
    onSelectUpdateNote(selectedItem) {
      this.showEditNoteModal = true;
      this.orderNoteModel = new UpdateNoteModel(selectedItem);
    },
    async onUpdateNote() {
      try {
        await ApiService.patch(
          `valuation-requests/${this.orderNoteModel.id}/note`,
          {
            ...this.orderNoteModel,
          },
        );
        this.getListCustomerSupportForm();
      } catch (error) {
        if (!error.response) {
          makeToastFaile('Lỗi!');
        } else {
          const message = error.response.data.message;
          makeToastFaile(message);
        }
      } finally {
        this.showEditNoteModal = false;
      }
    },
  },
  created() {
    this.handleFilter();
    this.getListCustomerSupportForm();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Yêu cầu định giá', route: '/valuation-request' },
      { title: 'Danh sách yêu cầu định giá' },
    ]);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.valuation-request-list {
  .checkbox-style {
    position: absolute;
    right: 0.7vw;
  }

  .custom-card-body {
    padding: 0 2.25rem;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(11% - 0.25rem);
    background-color: #f0f1f9;
  }

  .wizard.wizard-4
    .wizard-nav
    .wizard-steps
    .wizard-step[data-wizard-state='current'] {
    background-color: #ffffff;
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(17% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
}
</style>
