<template>
  <div class="row">
    <b-dropdown
      size="sm"
      class="mr-4"
      variant="warning"
      right
      :disabled="!selectedValuationRequestCount"
    >
      <template slot="button-content">
        <span class="font-weight-bolder">
          <span class="count-status badge badge-circle badge-white mr-1">{{ selectedValuationRequestCount }}</span>
          Đã chọn
        </span>
      </template>
      <b-dropdown-item @click="onShowDialogSelectedRequiredStocks">
        <i
          style="font-size: 1rem"
          class="fas fa-sync-alt icon-color"
        ></i>
        &nbsp; Đổi trạng thái
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
const { checkPermission } = require('@/utils/saveDataToLocal');
import { SET_VALUATION_REQUEST_SELECTED } from '@/core/services/store/modules/trade-in/valuation-request.module.js';
export default {
  props: ['selectedValuationRequest'],
  computed: {
    selectedValuationRequestCount() {
      return this.selectedValuationRequest.length;
    },
  },
  methods: {
    checkPermission,
    onShowDialogSelectedRequiredStocks() {
      this.$store.dispatch(
        SET_VALUATION_REQUEST_SELECTED,
        this.selectedValuationRequest,
      );
      this.$nextTick(() => this.$emit('show-dialog'));
    },
  },
};
</script>
