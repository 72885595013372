<template>
  <div class="warranty-request-table">
    <b-table
      :items="customerSupportForms"
      :fields="customerSupportFormFields"
      sort-by="count"
      class="table-bordered table-hover col-md-12"
      :busy="onLoading"
      selected-variant="success"
      :tbody-tr-class="rowClass"
    >
      <template v-slot:table-busy>
        <b-overlay rounded="sm">
        </b-overlay>
      </template>

      <template #head(index)>
        <b-form-checkbox
          size="lg"
          class="text-center"
          @change="selectAll"
          :checked="isCheckAll"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(index)="row">
        <b-form-checkbox
          size="lg"
          class="text-center"
          name="selected-items"
          v-model="selectedValuationRequestModel"
          :value="row.item"
        >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(id)="row">

        <div class="text-left">
          <div
            class="btn-link cursor-pointer text-center"
            @click="editItem(row.item)"
          >
            #<span v-text="row.item.id"></span>
          </div>
        </div>
      </template>
      <template #cell(customerName)="row">
        <p class="text"> <i
            style="font-size: 1rem"
            class="flaticon2-time mr-1 text-primary font-weight-bolder"
          ></i>{{ row.item.createdAt }}</p>

        <b class="text-">{{ row.item.customerName }}</b>
        <span class="cursor-pointer d-block mb-1">
          {{ row.item.customerPhoneNo }}
        </span>
      </template>

      <template v-slot:cell(productName)="row">
        <p class="mb-0">
          {{ row.item.productName }}
        </p>
        <p
          class="text-imei-no mb-0 btn-link"
          v-if="row.item.imeiNo"
        >IMEI: {{ row.item.imeiNo }}</p>
        <p class="dashed text my-2"></p>
        <p
          class="text-imei-no mb-0"
          v-if="row.item.productBuyingPrice"
        ><span class="font-weight-bold">Giá thu trên sản phẩm:</span> {{ convertPrice(row.item.productBuyingPrice) }}</p>
        <p
          class="text-imei-no mb-0"
          v-if="row.item.productBuyingPrice"
        ><span class="font-weight-bold">Phát sinh sau đánh giá:</span> {{ convertPrice(row.item.totalCriteriaPrice) || 0 }}</p>
      </template>

      <template #cell(note)="data">
        <i
          v-show="data.item.note"
          style="font-size: 1rem"
          class="flaticon2-contract pr-0"
        ></i> {{ data.item.note }}
      </template>

      <template #cell(status)="row">
        <span
          style="width: max-content"
          v-text="ENUM_NAME.VALUATION_REQUEST_STATUS_NAME[row.item.status] || ''"
          :class="bindClassNameStatus(row.item)"
        ></span>
        <p
          class="mb-0 text-warning btn-link cursor-pointer"
          v-if="row.item.tradeInId"
        >
          {{ row.item.tradeInId }}
        </p>
        <p
          class="mb-0 font-weight-bolder"
          v-if="row.item.updatedBy"
        >
          {{ row.item.updatedAt }}
        </p>
        <p
          class="mb-0"
          v-if="row.item.updatedByName"
        >
          {{ row.item.updatedByName }}
        </p>
      </template>
      <template #cell(actions)="row">
        <div class="d-flex justify-content-center">
          <b-dropdown
            size="sm"
            id="dropdown-left"
            no-caret
            right
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem"
                class="flaticon2-settings pr-0"
              ></i>

            </template>
            <b-dropdown-item @click="navigateToUpsert(row.item)">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem"
                  class="flaticon2-pen"
                ></i>
                &nbsp; Chỉnh sửa
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('update-note', row.item)">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem"
                  class="flaticon2-notepad"
                ></i>
                &nbsp; Ghi chú
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import { formatDate } from '@/utils/common';
import * as ENUM from '../../../utils/enum';
import * as ENUM_NAME from '../../../utils/enum-name';
import { convertPrice } from '@/utils/common';

export default {
  components: {},
  props: {
    customerSupportForms: {
      type: Array,
      default: () => [],
    },
    selectedValuationRequest: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedValuationRequestCount() {
      return this.selectedValuationRequestModel
        ? this.selectedValuationRequestModel.length
        : 0;
    },
    isCheckAll() {
      return (
        this.selectedValuationRequestCount === this.customerSupportForms.length
      );
    },
    selectedValuationRequestModel: {
      get: function () {
        return this.selectedValuationRequest;
      },
      set: function (newValue) {
        this.$emit('update:selectedValuationRequest', newValue);
        return;
      },
    },
  },
  data() {
    return {
      ENUM,
      ENUM_NAME,
      onLoading: false,
      customerSupportFormFields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '2%' },
          class: 'text-center',
        },
        {
          key: 'id',
          label: 'ID',
          thStyle: { textAlign: 'center', width: '4%' },
          class: 'text-center',
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: {
            width: '13%',
          },
          tdClass: 'text-wrap',
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            width: '35%',
          },
        },
        {
          key: 'note',
          label: 'Nội dung',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          tdClass: 'text-center',
          thStyle: {
            width: '8%',
          },
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
    };
  },
  methods: {
    formatDate,
    convertPrice,
    editBills(billNumber) {
      this.$router.push({
        name: 'update-bill',
        query: { id: billNumber },
      });
    },
    bindClassNameStatus(customerSupport) {
      let className = '';
      switch (customerSupport.status) {
        case ENUM.VALUATION_REQUEST_STATUS.NEW: {
          className = 'badge badge-warning text-white';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.PROCESSING: {
          className = 'badge badge-danger text-dark';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.NO_CONTACT: {
          className = 'badge badge-secondary';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.SPAM: {
          className = 'badge badge-secondary';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.CONTACTED: {
          className = 'badge badge-primary text-white';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.SUCCESS: {
          className = 'badge badge-success';
          break;
        }
        case ENUM.VALUATION_REQUEST_STATUS.CONFIRMED: {
          className = 'badge badge-primary text-white';
          break;
        }
      }
      return className;
    },
    selectAll() {
      if (
        this.selectedValuationRequestModel.length ===
        this.customerSupportForms.length
      ) {
        this.selectedValuationRequestModel = [];
      } else {
        this.selectedValuationRequestModel = this.customerSupportForms.slice();
      }
    },
    rowClass(item) {
      return this.selectedValuationRequestModel.indexOf(item) > -1
        ? 'table-secondary'
        : '';
    },
    navigateToUpsert(item) {
      this.$router.push({
        name: 'upsert-valuation-request',
        query: { id: item.id },
      });
    },
    navigateToCreateTradeIn(item) {
      this.$router.push({
        name: 'update-trade-in',
        query: { valuationRequestId: item.id },
      });
    },
    onChangeEachValuationRequest(item) {
      this.$emit('select', item);
    },
    isRowChecked(selectedItem) {
      return (
        (this.selectedValuationRequestModel || []).findIndex(
          (item) => item.id === selectedItem.id,
        ) > -1
      );
    },
  },
};
</script>

<style lang="scss">
.warranty-request-table {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table thead tr > th {
    text-align: center;
  }
  .text-link:hover {
    text-decoration: underline;
  }
  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #464e5f;
  }
  .pre-line {
    white-space: pre-line;
  }

  .icon-color {
    color: #464e5f;
  }
}
</style>
