<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <div class="d-flex">
          <b-input-group>
            <date-picker
              placeholder="Từ"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.fromDate"
            ></date-picker>
          </b-input-group>
          <span class="ml-1 mr-1"></span>
          <b-input-group>
            <date-picker
              placeholder="Đến"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.toDate"
            ></date-picker>
          </b-input-group>
        </div>
      </b-col>
      <b-col>
        <Autosuggest
          :model="apiParams.customerPhoneSearch"
          :suggestions="optionsCustomerPhone"
          :suggestionName="'phoneNo'"
          :placeholder="'sđt khách hàng'"
          :limit="10"
          @select="onSelectedCustomerPhone"
          @change="onChangeCustomerPhone"
        >
          <template #custom="{ suggestion }">
            <div class="d-flex flex-column">
              <span
                class="text-primary mb-1"
                v-if="suggestion.item.phoneNo"
              >({{ suggestion.item.phoneNo }})</span>
              <span>{{ suggestion.item.fullName }}</span>
            </div>
          </template>
        </Autosuggest>
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Nội dung phiếu"
          v-model="apiParams.description"
          v-on:keyup.enter="onFilter()"
        />
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Ghi chú"
          v-model="apiParams.note"
          v-on:keyup.enter="onFilter()"
        />
      </b-col>

      <b-col>
        <b-form-select
          size="sm"
          v-model="apiParams.status"
          :options="warrantyStatus"
          class="select-style"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-col>
      <b-col></b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col cols="1">
        <b-button
          size="sm"
          variant="primary"
          style="fontweight: 600; width: 70px"
          @click="onFilter()"
        >Lọc</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { DP_CONFIG } from '@/utils/date';
import { TIME_TRIGGER } from '@/utils/constants';
import { VALUATION_REQUEST_STATUS_NAME } from '@/utils/enum-name';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';

export default {
  components: {
    Autosuggest,
  },
  props: {
    apiParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dpConfigs: null,
      warrantyStatus: [],
      isSearchingCustomerPhone: false,
      filteredOptionsCustomerPhone: [],
    };
  },
  computed: {
    optionsCustomerPhone() {
      return [...this.filteredOptionsCustomerPhone];
    },
  },
  created() {
    this.onInitData();
    this.dpConfigs = DP_CONFIG;
  },
  methods: {
    onInitData() {
      const convertedWarrantyStatus = Object.keys(
        VALUATION_REQUEST_STATUS_NAME,
      ).map((key) => ({
        id: key,
        name: VALUATION_REQUEST_STATUS_NAME[key],
      }));
      convertedWarrantyStatus.unshift({
        id: null,
        name: 'Chọn trạng thái',
      });
      this.warrantyStatus = convertedWarrantyStatus;
    },
    onFilter() {
      this.$emit('filter');
    },
    debounceSearchCustomerPhone: decounce(function () {
      if (!this.isSearchingCustomerPhone) {
        this.getListCustomer();
      }
    }, TIME_TRIGGER),
    getListCustomer() {
      const searchText = this.apiParams.customerPhoneSearch;
      this.isSearchingCustomerPhone = true;
      this.filteredOptionsCustomerPhone = [];

      ApiService.get(`customer/get-by-phone?phoneNumber=${searchText}`).then(
        (data) => {
          const customers = data.data.data;
          this.filteredOptionsCustomerPhone = customers || [];
          this.isSearchingCustomerPhone = false;
        },
      );
    },
    onSelectedCustomerPhone(option) {
      if (!option) return;
      this.apiParams.customerId = option.item.id;
    },
    getSuggestionCustomerPhoneValue(suggestion) {
      return suggestion.item.phoneNo;
    },
    onChangeCustomerPhone(textInput) {
      const searchText = textInput ? textInput.trim() : null;
      this.apiParams.customerPhoneSearch = searchText;
      this.debounceSearchCustomerPhone();
    },
  },
};
</script>

<style>
</style>
